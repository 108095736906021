import { CloseCircleOutlined } from '@ant-design/icons';
import { GreenCheckMarkIcon } from '@marine/icons';
import { Grid, Tooltip } from 'antd';
import React from 'react';
import {
  ComponentBlocksAvailableOn,
  Enum_Feature_Group as EnumGroup,
  Enum_Feature_Subgroup,
  FeatureEntity,
  PlanEntity,
} from 'services/graphql/cms';
import styles from './PricingPlan.module.scss';

type Props = {
  plans: (
    | PlanEntity
    | {
        id: string;
        attributes: {
          title: null;
          icon: null;
          price: null;
        };
      }
  )[];
  features: FeatureEntity[];
  group: EnumGroup;
};

const CompareTable = ({ group, features, plans }: Props) => {
  const { xl, md } = Grid.useBreakpoint();

  const firstPlanTitle = plans !== null && plans[1]?.attributes?.title;

  const secondPlanTitle = plans !== null && plans[2]?.attributes?.title;

  const thirdPlanTitle = plans !== null && plans[3]?.attributes?.title;

  const forthPlanTitle = plans !== null && plans[4]?.attributes?.title;

  const createFirstTD = (data: ComponentBlocksAvailableOn) => {
    if (!data || data?.availabilityTitle?.toLowerCase() === 'limit') {
      return (
        <td className={styles.tableTdCenter}>
          <CloseCircleOutlined className={styles.notAvailableIconStyles} />
        </td>
      );
    }
    const { availabilitySubtitle, availabilityTitle } = data;

    if (!availabilityTitle && !availabilitySubtitle) {
      return (
        <td
          style={{
            backgroundColor: 'var(--gray-1)',
          }}
          className={styles.tableTdCenter}
        >
          <GreenCheckMarkIcon />
        </td>
      );
    }
    if (availabilityTitle && !availabilitySubtitle) {
      return (
        <td
          style={{
            backgroundColor: '#fff',
          }}
          className={styles.tableTdCenter}
        >
          <p>{availabilityTitle}</p>
        </td>
      );
    }

    return (
      <td
        style={{
          backgroundColor: 'var(--gray-1)',
        }}
        className={styles.tableTdCenter}
      >
        <p>
          {availabilityTitle}
          <span>{` (${availabilitySubtitle})`}</span>
        </p>
      </td>
    );
  };

  const createSecondTD = (data: ComponentBlocksAvailableOn) => {
    if (!data || data?.availabilityTitle?.toLowerCase() === 'limit') {
      return (
        <td
          style={{
            backgroundColor: 'var(--gray-1)',
          }}
          className={styles.tableTdCenter}
        >
          <CloseCircleOutlined className={styles.notAvailableIconStyles} />
        </td>
      );
    }
    const { availabilitySubtitle, availabilityTitle } = data;

    if (!availabilityTitle && !availabilitySubtitle) {
      return (
        <td
          style={{
            backgroundColor: 'var(--gray-1)',
          }}
          className={styles.tableTdCenter}
        >
          <GreenCheckMarkIcon />
        </td>
      );
    }
    if (availabilityTitle && !availabilitySubtitle) {
      return (
        <td
          style={{
            backgroundColor: '#fff',
          }}
          className={styles.tableTdCenter}
        >
          <p>{availabilityTitle}</p>
        </td>
      );
    }

    return (
      <td
        style={{
          backgroundColor: 'var(--gray-1)',
        }}
        className={styles.tableTdCenter}
      >
        <p>
          {availabilityTitle}
          <span>{` (${availabilitySubtitle})`}</span>
        </p>
      </td>
    );
  };

  // To keep in mind this is only for 2 plans available
  const createTd = (items: any) => (
    <>
      {createFirstTD(
        items.find((item: ComponentBlocksAvailableOn) => item?.plan?.data?.attributes?.title === firstPlanTitle),
      )}
      {createSecondTD(
        items.find((item: ComponentBlocksAvailableOn) => item?.plan?.data?.attributes?.title === secondPlanTitle),
      )}
      {createFirstTD(
        items.find((item: ComponentBlocksAvailableOn) => item?.plan?.data?.attributes?.title === thirdPlanTitle),
      )}
      {createSecondTD(
        items.find((item: ComponentBlocksAvailableOn) => item?.plan?.data?.attributes?.title === forthPlanTitle),
      )}
    </>
  );
  const createTableItemData = (data: any) =>
    md ? (
      createTd(data)
    ) : (
      <table>
        <tbody>
          <tr>{createTd(data)}</tr>
        </tbody>
      </table>
    );
  const createRow = (feature: FeatureEntity) =>
    !md ? (
      <td colSpan={xl ? 0 : 2} style={{ backgroundColor: 'transparent' }}>
        <Tooltip
          placement="bottomRight"
          title={<div dangerouslySetInnerHTML={{ __html: feature?.attributes?.description || '' }} />}
        >
          <h4>{feature?.attributes?.title}</h4>
        </Tooltip>
        {/* <p dangerouslySetInnerHTML={{ __html: `${feature?.attributes?.description}` }} />
        {feature?.attributes?.badge ? <div className={styles.badge}>{feature?.attributes?.badge}</div> : null} */}
        {createTableItemData(feature?.attributes?.availableOn)}
      </td>
    ) : (
      <>
        <td colSpan={xl ? 0 : 2}>
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: feature?.attributes?.description || '' }} />}>
            <h4>{feature?.attributes?.title}</h4>
          </Tooltip>
          {/* <p dangerouslySetInnerHTML={{ __html: `${feature?.attributes?.description}` }} />
          {feature?.attributes?.badge ? <div className={styles.badge}>{feature?.attributes?.badge}</div> : null} */}
        </td>
        {createTableItemData(feature?.attributes?.availableOn)}
      </>
    );

  if (group === EnumGroup.TheBoatApp) {
    return (
      <table key={group} className={styles.compareTableContainer}>
        <>
          <thead>
            <tr>
              {plans.map((item, index) => {
                if (!xl) {
                  if (index > 0) {
                    if (md) {
                      return (
                        <th
                          key={item.id}
                          style={{
                            backgroundColor: 'var(--gray-1)',
                          }}
                        />
                      );
                    }

                    return null;
                  }
                }

                return (
                  <th
                    key={item.id}
                    colSpan={xl ? 0 : 2}
                    style={{
                      backgroundColor: '#EBEBED',
                    }}
                  >
                    <div>
                      <p>{index > 0 ? null : group}</p>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {features
              .filter((item) => !item?.attributes?.subGroup)
              .map((feature) => (
                <tr key={feature?.id}>{createRow(feature)}</tr>
              ))}
          </tbody>
        </>
        {Object.values(Enum_Feature_Subgroup).map((subGroup) => (
          <React.Fragment key={subGroup}>
            <thead>
              <tr>
                {plans.map((item, index) => {
                  if (!xl) {
                    if (index > 0) {
                      if (md) {
                        return (
                          <th
                            key={item.id}
                            style={{
                              backgroundColor: 'var(--gray-1)',
                            }}
                          />
                        );
                      }

                      return null;
                    }
                  }

                  return (
                    <th
                      key={item.id}
                      colSpan={xl ? 0 : 2}
                      style={{
                        backgroundColor: 'var(--gray-1)',
                      }}
                    >
                      <div>
                        <p>{index > 0 ? null : subGroup}</p>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {features
                .filter((item) => item?.attributes?.subGroup === subGroup)
                .map((feature) => (
                  <tr key={feature?.id}>{createRow(feature)}</tr>
                ))}
            </tbody>
          </React.Fragment>
        ))}
      </table>
    );
  }

  return (
    <table key={group} className={styles.compareTableContainer}>
      <thead>
        <tr>
          {plans.map((item, index) => {
            if (!xl) {
              if (index > 0) {
                if (md) {
                  return (
                    <th
                      key={item.id}
                      style={{
                        backgroundColor: 'var(--gray-1)',
                      }}
                    />
                  );
                }

                return null;
              }
            }

            return (
              <th
                key={item.id}
                colSpan={xl ? 0 : 2}
                style={{
                  backgroundColor: '#EBEBED',
                }}
              >
                <div>
                  <p>{index > 0 ? null : group}</p>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {features.map((feature) => (
          <tr key={feature?.id}>{createRow(feature)}</tr>
        ))}
      </tbody>
    </table>
  );
};

export default CompareTable;
