import {
  BookOutlined,
  BulbOutlined,
  CloudOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FileAddOutlined,
  FileOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FundOutlined,
  HeartOutlined,
  LikeOutlined,
  MessageOutlined,
  PieChartOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  SearchOutlined,
  ShareAltOutlined,
  SolutionOutlined,
  SoundOutlined,
  StopOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  AlarmIcon,
  BoatIcon,
  BoatTwoIcon,
  CommunityIcon,
  CompareIcon,
  DoubleCheckIcon,
  ExplorerIcon,
  ExternalLinkIcon,
  GoldIcon,
  LifeBuoyIcon,
  MarkerIcon,
  OfflineIcon,
  PlatinumIcon,
  SailBoatIcon,
  ScaleIcon,
  ShareFileIcon,
  SilverIcon,
  SuitcaseIcon,
  TaskCheckListIcon,
  UserHeartIcon,
  UsersIcon,
  WindIcon,
} from '@marine/icons';

const iconsMap: Record<string, JSX.Element> = {
  sailBoat: <SailBoatIcon />,
  boatIcon: <BoatIcon />,
  compareIcon: <CompareIcon />,
  explorerIcon: <ExplorerIcon />,
  boatTwoIcon: <BoatTwoIcon />,
  heartOutlined: <HeartOutlined />,
  userHeartIcon: <UserHeartIcon />,
  doubleCheckIcon: <DoubleCheckIcon />,
  messageOutlined: <MessageOutlined />,
  solutionOutlined: <SolutionOutlined />,
  bulbOutlined: <BulbOutlined />,
  fundOutlined: <FundOutlined />,
  questionCircleOutlined: <QuestionCircleOutlined />,
  externalLinkIcon: <ExternalLinkIcon />,
  fileTextOutlined: <FileTextOutlined />,
  communityIcon: <CommunityIcon />,
  customerServiceOutlined: <CustomerServiceOutlined />,
  suitcaseIcon: <SuitcaseIcon />,
  profileOutlined: <ProfileOutlined />,
  FileProtectOutlined: <FileProtectOutlined />,
  fileProtectOutlined: <FileProtectOutlined />,
  goldIcon: <GoldIcon />,
  GoldIcon: <GoldIcon />,
  silverIcon: <SilverIcon />,
  platinumIcon: <PlatinumIcon />,
  scaleIcon: <ScaleIcon />,
  usersIcon: <UsersIcon />,
  markerIcon: <MarkerIcon />,
  windIcon: <WindIcon />,
  shareFileIcon: <ShareFileIcon />,
  taskCheckListIcon: <TaskCheckListIcon />,
  alarmIcon: <AlarmIcon />,
  lifeBuoyIcon: <LifeBuoyIcon />,
  offlineIcon: <OfflineIcon />,
  stopOutlined: <StopOutlined />,
  soundOutlined: <SoundOutlined />,
  likeOutlined: <LikeOutlined />,
  dashboardOutlined: <DashboardOutlined />,
  searchOutlined: <SearchOutlined />,
  cloudOutlined: <CloudOutlined />,
  fileAddOutlined: <FileAddOutlined />,
  shareAltOutlined: <ShareAltOutlined />,
  userOutlined: <UserOutlined />,
  saveOutlined: <SaveOutlined />,
  bookOutlined: <BookOutlined />,
  databaseOutlined: <DatabaseOutlined />,
  unorderedListOutlined: <UnorderedListOutlined />,
  fileOutlined: <FileOutlined />,
  pieChartOutlined: <PieChartOutlined />,
};

export default function iconMap(iconName: string) {
  return iconsMap[iconName] ? iconsMap[iconName] : null;
}
